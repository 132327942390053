import React from "react";
import { useGlobalContext } from "../context";
import LogoMobile from "../assets/images/logo-mobile.svg";
import styled from "styled-components";
import { Link } from "gatsby";
import { FaBars, FaRegTimesCircle } from "react-icons/fa";
import { navigation } from "../navigation";
import { respondTo } from "../styles/respond-to";

const HeaderStyles = styled.div`
  .logo-mobile {
    display: block;

    ${respondTo.md`
      width: 50%;
      margin: 1.5rem auto;
    `}

    ${respondTo.sm`
      width: 95%;
      margin: 1rem auto;
    `}
    
  }
`;

const MenuBarStyles = styled.div`
  display: flex;
  justify-content: flex-start;
  background-color: var(--palette-dark-brown);
`;

const MobileNavStyles = styled.div`
  position: absolute;
  bottom: 0;
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 80vh;
  background-color: var(--palette-blue);
  color: white;
  border-top-left-radius: 10;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding: 1rem 1rem 0 1.4rem;
  margin: 0;
  overflow: hidden;
  transition: all 0.5s ease-out;
  box-shadow: -3px -3px 8px rgba(0, 0, 0, 0.5);

  ${respondTo.md`
    width: 50vw;
  `}

  ${respondTo.sm`
    width: 85vw;
  `}

  a {
    color: white;
    margin-bottom: 1rem;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 1rem;
    letter-spacing: 1px;

    svg {
      margin-right: 0.8rem;
    }
  }
`;

const MobileNav = () => {
  const { mobileNav, hideMobileNav } = useGlobalContext();

  return (
    <MobileNavStyles
      className={`${mobileNav ? "mobile-nav-show" : "mobile-nav-hide"}`}
    >
      <button
        className="menu-btn"
        type="button"
        onClick={hideMobileNav}
        style={{ alignSelf: "flex-end", marginBottom: "60px" }}
      >
        <FaRegTimesCircle />
      </button>

      {navigation.map((link, index) => {
        const { url, label, icon } = link;
        return (
          <Link to={url} onClick={hideMobileNav}>
            {icon} {label}
          </Link>
        );
      })}
    </MobileNavStyles>
  );
};

const MenuBar = () => {
  const { mobileNav, toggleMobileNav } = useGlobalContext();

  return (
    <MenuBarStyles>
      <button
        className={`${
          mobileNav
            ? "menu-btn menubar-btn menubar-btn-active"
            : "menu-btn menubar-btn"
        }`}
        type="button"
        onClick={toggleMobileNav}
      >
        <FaBars />
      </button>
    </MenuBarStyles>
  );
};

const MobileHeader = () => {
  return (
    <>
      <HeaderStyles>
        <Link to="/">
          <img className="logo-mobile" src={LogoMobile} alt="Discover PA Six" />
        </Link>
      </HeaderStyles>

      <MenuBar />

      <MobileNav />
    </>
  );
};

export default MobileHeader;
