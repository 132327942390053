import React from "react";
import { FaFacebook } from "react-icons/fa";
import {
  BiHome,
  BiHomeHeart,
  BiWrench,
  BiLink,
  BiStreetView,
  BiMessageRoundedDetail,
  BiInfoCircle,
} from "react-icons/bi";

export const navigation = [
  {
    label: "Home",
    icon: <BiHome />,
    url: "/",
  },
  {
    label: "Work",
    icon: <BiWrench />,
    url: "/work",
  },
  {
    label: "Live",
    icon: <BiHomeHeart />,
    url: "/live",
  },
  {
    label: "Discover",
    icon: <BiStreetView />,
    url: "/discover",
  },
  {
    label: "Realtors",
    icon: <BiHomeHeart />,
    url: "/realtors",
  },
  {
    label: "Testimonials",
    icon: <BiMessageRoundedDetail />,
    url: "/testimonials",
  },
  {
    label: "About",
    icon: <BiInfoCircle />,
    url: "/about",
  },
  {
    label: "Connect",
    icon: <BiLink />,
    url: "/connect",
  },
  {
    label: "Facebook",
    icon: <FaFacebook />,
    url: "https://www.facebook.com/worforcesolutionspa/",
  },
];

/*
  {
    label: "Advanced Manufacturing",
    icon: <FaCalculator />,
    url: "/work/advanced-manufacturing",
  },
  {
    label: "Agriculture & Food Production",
    icon: <FaCalculator />,
    url: "/work/agriculture-food-production",
  },
  {
    label: "Bio Medical",
    icon: <FaCalculator />,
    url: "/work/bio-medical",
  },
  {
    label: "Building & Construction",
    icon: <FaCalculator />,
    url: "/work/building-construction",
  },
  {
    label: "Business Services",
    icon: <FaCalculator />,
    url: "/work/business-services",
  },
  {
    label: "Education",
    icon: <FaCalculator />,
    url: "/work/education",
  },
  {
    label: "Energy",
    icon: <FaCalculator />,
    url: "/work/energy",
  },
  {
    label: "Health Care & Social Assistance",
    icon: <FaCalculator />,
    url: "/work/health-care",
  },
  {
    label: "Hospitality, Leisure and Entertainment",
    icon: <FaCalculator />,
    url: "/work/hospitality-leisure-entertainment",
  },
  {
    label: "Logistics & Transportation",
    icon: <FaCalculator />,
    url: "/work/logistics-transportation",
  },
  {
    label: "Real Estate, Finance & Insurance",
    icon: <FaCalculator />,
    url: "/work/real-estate-finance-insurance",
  },
  {
    label: "Wood Products & Publishing",
    icon: <FaCalculator />,
    url: "/work/wood-publishing",
  },

*/
