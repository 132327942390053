import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { useLocation } from "@reach/router";

export default function Seo(props) {
  const { site, featuredImage } = useStaticQuery(graphql`
    query SeoMetaData {
      site {
        siteMetadata {
          title
          author
          description
          siteUrl
          og {
            siteName
            twitterCreator
          }
        }
      }

      featuredImage: file(
        absolutePath: { glob: "**/src/images/featured-image.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 1200)
        }
      }
    }
  `);

  const ogImage =
    props.featuredImage ?? featuredImage?.childImageSharp?.gatsbyImageData;

  const title = props.title ?? site?.siteMetadata?.title;
  const description = props.description ?? site?.siteMetadata?.description;

  const location = useLocation();

  const metas = [
    { name: "description", content: description },
    { name: "og:image", content: ogImage.images.fallback.src },
    { name: "og:image:width", content: `${ogImage.width}` },
    { name: "og:image:height", content: `${ogImage.height}` },
    { name: "og:type", content: "website" },
    { name: "og:title", content: title },
    { name: "og:description", content: description },
    { name: "og:site_name", content: site.siteMetadata.og.siteName },
    {
      name: "og:url",
      content: `${site?.siteMetadata?.siteUrl}${location.pathname}`,
    },
    { name: "twitter:card", content: "summary_large_image" },
    { name: "twitter:description", content: description },
    { name: "twitter:title", content: title },
    {
      name: "twitter:image",
      content: `${site.siteMetadata.siteUrl}${ogImage.images.fallback.src}`,
    },
    { name: "twitter:creator", content: site.siteMetadata.og.twitterCreator },
    { name: "twitter:site", content: site.siteMetadata.og.twitterCreator },
    { name: "author", content: site.siteMetadata.author },
  ];

  if (props.keywords) {
    metas.push({ name: "keywords", content: props.keywords });
  }

  return (
    <Helmet>
      <html lang="en" />
      <meta charSet="utf-8" />
      <title>{title}</title>

      {metas.map((meta) => (
        <meta key={meta.name} name={meta.name} property={meta.name} content={meta.content} />
      ))}
    </Helmet>
  );
}
