import useWindowSize from "./useWindowSize"

const useMobile = () => {
  const {width} = useWindowSize()

  if (width <= 768) {
    return true
  } 

  return false
}

export default useMobile
