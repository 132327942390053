import styled from "styled-components"

const StyledSubmenu = styled.div`
  position: absolute;
  z-index: 10;
  width: 350px;
  top: 26px;
  left: 3px;
  padding: 1rem;
  border: 1px solid rgba(255, 255, 255, 0.5);
  background-color: rgba(151, 93, 33, 0.8);

  a.submenu-item {
    display: block;
    font-size: .8rem;
    margin: 0 0 .7rem;
    padding: 0 0;
  }
`

export default StyledSubmenu;
