import React from "react";
import { Link } from "gatsby";
import StyledSubmenu from "./styled-submenu";

const LiveSubmenu = () => {
  return (
    <StyledSubmenu>
      <Link
        className="submenu-item"
        to="/counties/cameron"
        activeClassName="active"
      >
        Cameron County
      </Link>
      <Link
        className="submenu-item"
        to="/counties/clearfield"
        activeClassName="active"
      >
        Clearfield County
      </Link>
      <Link
        className="submenu-item"
        to="/counties/elk"
        activeClassName="active"
      >
        Elk County
      </Link>
      <Link
        className="submenu-item"
        to="/counties/jefferson"
        activeClassName="active"
      >
        Jefferson County
      </Link>
      <Link
        className="submenu-item"
        to="/counties/mckean"
        activeClassName="active"
      >
        McKean County
      </Link>
      <Link
        className="submenu-item"
        to="/counties/potter"
        activeClassName="active"
      >
        Potter County
      </Link>
    </StyledSubmenu>
  );
};

export default LiveSubmenu;
