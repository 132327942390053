import React, { useState } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import LiveSubmenu from "./live-submenu";
import WorkSubmenu from "./work-submenu";

const NavStyles = styled.nav`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 1rem;
  margin-right: 2rem;

  a {
    position: relative;
    display: block;
    padding: 0 1rem;

    &:last-of-type {
      padding-right: 0;
    }

    color: white;
    text-transform: uppercase;
    text-decoration: none;
    &:hover {
      color: var(--yellow-hover);
    }

    &.active {
      color: var(--yellow-hover);
    }
  }
`;

const Nav = () => {
  const [liveDropdown, setLiveDropdown] = useState(false);
  const showLiveDropdown = () => {
    setWorkDropdown(false);
    setLiveDropdown(true);
  };
  const hideLiveDropdown = () => setLiveDropdown(false);

  const [workDropdown, setWorkDropdown] = useState(false);
  const showWorkDropdown = () => {
    setLiveDropdown(false);
    setWorkDropdown(true);
  };
  const hideWorkDropdown = () => setWorkDropdown(false);

  return (
    <>
      <NavStyles>
        <Link
          to="/work"
          activeClassName="active"
          onMouseEnter={showWorkDropdown}
          onMouseLeave={hideWorkDropdown}
        >
          Work
          {workDropdown && <WorkSubmenu />}
        </Link>
        <Link
          to="/live"
          activeClassName="active"
          onMouseEnter={showLiveDropdown}
          onMouseLeave={hideLiveDropdown}
        >
          Live
          {liveDropdown && <LiveSubmenu />}
        </Link>
        <Link to="/discover" activeClassName="active">Discover</Link>

        <Link to="/realtors" activeClassName="active">Realtors</Link>

        <Link to="/testimonials" activeClassName="active">Testimonials</Link>
        <Link to="/about" activeClassName="active">About</Link>
        <Link to="/connect" activeClassName="active">Connect</Link>
      </NavStyles>
    </>
  );
};

export default Nav;
