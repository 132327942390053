import React from "react";
import { Link } from "gatsby";
import StyledSubmenu from "./styled-submenu";

const WorkSubmenu = () => {
  return (
    <StyledSubmenu>
      <Link
        className="submenu-item"
        to="/work/advanced-manufacturing"
        activeClassName="active"
      >
        Advanced Manufacturing
      </Link>
      <Link
        className="submenu-item"
        to="/work/agriculture-food-production"
        activeClassName="active"
      >
        Agriculture &amp; Food Production
      </Link>
      <Link
        className="submenu-item"
        to="/work/bio-medical"
        activeClassName="active"
      >
        Bio Medical
      </Link>
      <Link
        className="submenu-item"
        to="/work/building-construction"
        activeClassName="active"
      >
        Building &amp; Construction
      </Link>
      <Link
        className="submenu-item"
        to="/work/business-services"
        activeClassName="active"
      >
        Business Services
      </Link>
      <Link
        className="submenu-item"
        to="/work/education"
        activeClassName="active"
      >
        Education
      </Link>
      <Link className="submenu-item" to="/work/energy" activeClassName="active">
        Energy
      </Link>
      <Link
        className="submenu-item"
        to="/work/health-care"
        activeClassName="active"
      >
        Health Care &amp; Social Assistance
      </Link>
      <Link
        className="submenu-item"
        to="/work/hospitality-leisure-entertainment"
        activeClassName="active"
      >
        Hospitality, Leisure &amp; Entertainment
      </Link>
      <Link
        className="submenu-item"
        to="/work/logistics-transportation"
        activeClassName="active"
      >
        Logistics &amp; Transportation
      </Link>
      <Link
        className="submenu-item"
        to="/work/real-estate-finance-insurance"
        activeClassName="active"
      >
        Real Estate, Finance &amp; Insurance
      </Link>
      <Link
        className="submenu-item"
        to="/work/wood-publishing"
        activeClassName="active"
      >
        Wood Products &amp; Publishing
      </Link>
    </StyledSubmenu>
  );
};

export default WorkSubmenu;
