import React from "react";
import Logo from "./logo";
import Nav from "./nav";
import styled from "styled-components";

const StyledHeader = styled.header`
  padding: 1rem;
  width: 100vw;
  height: 140px;
  background-image: linear-gradient(
    rgba(27, 42, 54, 0.9),
    rgba(59, 79, 94, 0.65) 60%,
    rgba(0, 0, 0, 0)
  );
  display: flex;
  justify-content: space-between;
  position: relative;

  img {
    height: 100%;
  }
`;

const Header = () => {
  return (
    <StyledHeader>
      <Logo />
      <Nav />
    </StyledHeader>
  );
};

export default Header;
